/**
 * Shared
 */
export const CLASS_INFO_LABEL = 'Details'
export const CLASS_OUTLINE_LABEL = 'Outline'
export const REQUIRED_MATERIALS_LABEL = 'Required Materials'
export const WEEK = 'Week'

/**
 * Church Membership section
 */
export const CHURCH_MEMBERSHIP_DESCRIPTION = `Our membership classes are designed to teach the fundamentals of the Christian faith, including the Trinity, the inspiration of Scripture, and the work and the person of Jesus Christ. We also cover our doctrinal distinctives and the biblical principles by which we govern our church. If you're thinking about committing to CrossLife, we encourage you to take the classes to see if this is the local community in which you would like to live out your faith. The membership classes are divided into three lectures and cover the specific topics listed below.`
export const CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT =
  'Saturdays on May 4, May 11, and May 18. Check social media for updates!'
export const CHURCH_MEMBERSHIP_DURATION_CONTENT = '3 classes | 10AM - 1PM'
export const CHURCH_MEMBERSHIP_LOCATION_CONTENT = 'Church'
export const CLASS_1 = 'Class 1 (05/04/2024)'
export const CLASS_2 = 'Class 2 (05/11/2024)'
export const CLASS_3 = 'Class 3 (05/18/2024)'
export const OUTLINE_1 = 'The Purpose of Church Membership'
export const OUTLINE_2 = 'The Doctrines of the Christian Faith'
export const OUTLINE_2A = 'The Bible'
export const OUTLINE_2B = 'God'
export const OUTLINE_2C = 'The Son'
export const OUTLINE_2D = 'The Spirit'
export const OUTLINE_2E = 'Salvation'
export const OUTLINE_3 = 'Church Life'
export const OUTLINE_3A = 'Church Polity'
export const OUTLINE_3B = 'Elders and Deacons'
export const OUTLINE_3C = 'Worship'
export const OUTLINE_3CI = 'Expositional Preaching'
export const OUTLINE_3CII = 'Offering'
export const OUTLINE_3D = 'Ordinances of the Church'
export const OUTLINE_3DI = 'Baptism'
export const OUTLINE_3DII = 'Communion'
export const OUTLINE_3_CONTINUED = 'Church Life (cont.)'
export const OUTLINE_3E = 'Spiritual Gifts'
export const OUTLINE_3F = 'Church Discipline'

/**
 * Fundamentals of the Faith section
 */
export const FOF_DESCRIPTION_1 =
  'FOF is a class designed to simply and systematically present the basics of the Christian faith. This class is useful for those who are new to the faith and also for those who would like to review or fill in some of the gaps of the Christian fundamentals.'
export const FOF_DESCRIPTION_2 =
  'An essential component of the Christian walk is being equipped with knowledge of Scripture. Knowledge of the Word is important so that we can do our best to present ourselves to God as those approved, who rightly handle the Word of truth (cf. 2 Timothy 2:15). Furthermore, growing in the knowledge of the Son of God is important for maturing in the faith so that we grow confident in the truth and become less susceptible to deceitful and false doctrines (cf. Ephesians 4:13-14). Lastly, we desire to grow in knowledge of the Word, not merely for the sake of knowledge, but so that we can grow in godliness (cf. Titus 1:1) and exhort one another in the faith.'
export const FOF_DESCRIPTION_3 = `In this class we will work through a wide variety of topics ranging from the basic elements of how to study God's Word, to the Trinity, to the End Times (see below for a list of the weekly lectures). Each week, we will spend time reading Scripture, interpreting what we read, and compiling the truths we learn. There is also a portion of the class dedicated to Q&A each week so that we can work through some of the many challenging questions that arise when studying the Bible.`
export const FOF_UPCOMING_DATES_CONTENT =
  'Check the Discord announcements channel for specific dates and times.'
export const FOF_DURATION_CONTENT = '10 weeks'
export const FOF_LOCATION_CONTENT = 'Church'
export const FOF_WEEK_CONTENT: string[] = [
  'Orientation',
  `The Canonicity and Inspiration of God's Word`,
  'Basic Bible Timeline',
  `How to Study God's Word`,
  'God the Father',
  'The Person of Christ',
  'The Work of Christ',
  'Salvation and Faith',
  'The Holy Spirit and the Trinity',
  'The End Times',
  'Christian Living',
]

/**
 * Evangelism Training section
 */

export const ET_ALERT_TEXT_1 =
  "Evangelism Training is only available for members. If you're not a member, we highly recommend taking"
export const ET_ALERT_TEXT_2 = 'Fundamentals of the Faith (FOF)'
export const ET_ALERT_TEXT_3 = 'instead!'
export const ET_DESCRIPTION_1 =
  'In Matthew 28:18-20, our Lord has commissioned us to go out and to make disciples of all the nations. In order to obey His words and train our members to evangelize, we offer an evangelism training class on Sundays at 2:30PM. The course is designed to empower individuals to overcome their fear of sharing the Gospel and to help them proclaim the Truth coherently. Each individual will be paired with an experienced evangelist as they go out into the mission field (e.g. parks, UCI campus, malls) on a weekly basis.'
export const ET_UPCOMING_DATES_CONTENT =
  'Check the Discord announcements channel for specific dates and times.'
export const ET_DURATION_CONTENT = '10 weeks'
export const ET_LOCATION_CONTENT = 'Church'
export const ET_WEEK_CONTENT: string[] = [
  'Orientation',
  'Introduction',
  'Sin',
  'God',
  'Christ',
  'Midterm',
  'Grace',
  'Faith and Clarification',
  'Commitment',
  'Immediate Follow Up',
  'Final',
]
