import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import styled from 'styled-components'
import { CHURCH_LOCATION } from '../common/string'
import customTheme from '../theme'

const Container = styled.div`
  padding: 50px 0;
  text-align: center;

  ${customTheme.breakpoints.down('sm')} {
    padding: 40px 20px;
  }
`

const SocialLinks = styled(Stack)`
  margin: 0 0 20px 0;

  > a:not(:last-child) {
    margin: 0 20px 0 0;
  }
`

type StyledLinkProps = {
  imageUrl: string
}

const StyledLink = styled.a<StyledLinkProps>`
  height: 50px;
  width: 50px;
  background: url(${(props) => props.imageUrl});
  background-size: contain;
`

type IconLinkProps = {
  imageUrl: string
  destinationUrl: string
}

const IconLink: FC<IconLinkProps> = ({ imageUrl, destinationUrl }) => {
  return (
    <StyledLink
      href={destinationUrl}
      imageUrl={imageUrl}
      target="_blank"
      rel="noreferrer"
    />
  )
}

const Footer: FC = () => {
  const currentYear = new Date().getFullYear()
  return (
    <Container>
      <SocialLinks alignItems="center" justifyContent="center" direction="row">
        <IconLink
          imageUrl="https://assets.crosslifebiblechurch.org/instagram.svg"
          destinationUrl="https://www.instagram.com/crosslife_bible_church"
        />
        <IconLink
          imageUrl="https://assets.crosslifebiblechurch.org/email.svg"
          destinationUrl="mailto:planning@crosslifebiblechurch.org"
        />
        <IconLink
          imageUrl="https://assets.crosslifebiblechurch.org/youtube.svg"
          destinationUrl="https://www.youtube.com/channel/UCqP2J-XZEfUggoG6IDTvDJA"
        />
        <IconLink
          imageUrl="https://assets.crosslifebiblechurch.org/spotify.svg"
          destinationUrl="https://open.spotify.com/show/4eapyaQqOZ7yWxkse3zxDV"
        />
      </SocialLinks>
      <Typography variant="body1">
        © {currentYear} CrossLife Bible Church All Rights Reserved
      </Typography>
      <Typography variant="body1">{CHURCH_LOCATION}</Typography>
    </Container>
  )
}

export default Footer
