import { Alert, Typography } from '@mui/material'
import { FC } from 'react'
import {
  ADULT_SMALL_GROUP_DESCRIPTION,
  ADULT_SMALL_GROUP_INFO_ALERT_TEXT,
} from './strings'

const AdultSmallGroups: FC = () => {
  return (
    <>
      <Alert severity="info" sx={{ marginBottom: '25px' }}>
        {ADULT_SMALL_GROUP_INFO_ALERT_TEXT}
      </Alert>
      <Typography variant="body1">{ADULT_SMALL_GROUP_DESCRIPTION}</Typography>
    </>
  )
}

export default AdultSmallGroups
