import { FC } from 'react'
import styled from 'styled-components'
import { Paper, Stack, Typography } from '@mui/material'
import {
  CHURCH_MEMBERSHIP_DESCRIPTION,
  CHURCH_MEMBERSHIP_DURATION_CONTENT,
  CHURCH_MEMBERSHIP_LOCATION_CONTENT,
  CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT,
  CLASS_1,
  CLASS_2,
  CLASS_3,
  CLASS_INFO_LABEL,
  CLASS_OUTLINE_LABEL,
  OUTLINE_1,
  OUTLINE_2,
  OUTLINE_2A,
  OUTLINE_2B,
  OUTLINE_2C,
  OUTLINE_2D,
  OUTLINE_3,
  OUTLINE_3A,
  OUTLINE_3B,
  OUTLINE_3C,
  OUTLINE_3CI,
  OUTLINE_3CII,
  OUTLINE_3D,
  OUTLINE_3DI,
  OUTLINE_3DII,
  OUTLINE_3_CONTINUED,
  OUTLINE_3E,
  OUTLINE_3F,
  REQUIRED_MATERIALS_LABEL,
} from './strings'
import Paragraph from '../common/Paragraph'
import { PlanningMailToLink } from '../common/MailToLink'
import customTheme from '../theme'
import DetailsSection from './DetailsSection'

const ClassOutline = styled(Paper)`
  padding: 30px;
  background-color: #f9f7f7;
  margin-bottom: 30px;

  line-height: 1.5rem;

  ${customTheme.breakpoints.down('sm')} {
    padding: 20px;
  }
`

const ListStyling = styled.ol`
  padding-left: 20px;
`

const BookInfo = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
`

const PurchaseInfo = styled.div`
  margin-left: 20px;
`

const ChurchMembership: FC = () => {
  return (
    <>
      <Paragraph>{CHURCH_MEMBERSHIP_DESCRIPTION}</Paragraph>
      <Typography variant="h2" gutterBottom>
        {CLASS_INFO_LABEL}
      </Typography>
      <DetailsSection
        upcomingDates={CHURCH_MEMBERSHIP_UPCOMING_DATES_CONTENT}
        duration={CHURCH_MEMBERSHIP_DURATION_CONTENT}
        location={CHURCH_MEMBERSHIP_LOCATION_CONTENT}
        email={<PlanningMailToLink />}
      />
      <Typography variant="h2" gutterBottom>
        {CLASS_OUTLINE_LABEL}
      </Typography>
      <ClassOutline elevation={0} variant="secondary">
        <Typography variant="h5">{CLASS_1}</Typography>
        <ListStyling type="I">
          <li>{OUTLINE_1}</li>
          <li>{OUTLINE_2}</li>
          <ListStyling type="a">
            <li>{OUTLINE_2A}</li>
            <li>{OUTLINE_2B}</li>
            <li>{OUTLINE_2C}</li>
            <li>{OUTLINE_2D}</li>
          </ListStyling>
        </ListStyling>
      </ClassOutline>
      <ClassOutline elevation={0} variant="secondary">
        <Typography variant="h5">{CLASS_2}</Typography>
        <ListStyling type="I" start={3}>
          <li>{OUTLINE_3}</li>
          <ListStyling type="a">
            <li>{OUTLINE_3A}</li>
            <li>{OUTLINE_3B}</li>
            <li>{OUTLINE_3C}</li>
            <ol type="i">
              <li>{OUTLINE_3CI}</li>
              <li>{OUTLINE_3CII}</li>
            </ol>
            <li>{OUTLINE_3D}</li>
            <ol type="i">
              <li>{OUTLINE_3DI}</li>
              <li>{OUTLINE_3DII}</li>
            </ol>
          </ListStyling>
        </ListStyling>
      </ClassOutline>
      <ClassOutline elevation={0} variant="secondary">
        <Typography variant="h5">{CLASS_3}</Typography>
        <ListStyling type="I" start={3}>
          <li>{OUTLINE_3_CONTINUED}</li>
          <ListStyling type="a" start={5}>
            <li>{OUTLINE_3E}</li>
            <li>{OUTLINE_3F}</li>
          </ListStyling>
        </ListStyling>
      </ClassOutline>
      <Typography variant="h2" gutterBottom>
        {REQUIRED_MATERIALS_LABEL}
      </Typography>
      <Stack direction="column">
        <BookInfo>
          <img
            src="https://assets.crosslifebiblechurch.org/healthy-church-member.jpg"
            alt="What is a Healthy Church Member"
            width={150}
          />
          <PurchaseInfo>
            Purchase{' '}
            <a href="https://www.amazon.com/What-Healthy-Church-Member-Marks/dp/1433502127">
              here{' '}
            </a>
            or borrow from our church library. More info about our church
            library can be found on Discord.
          </PurchaseInfo>
        </BookInfo>
        <BookInfo>
          <img
            src="https://assets.crosslifebiblechurch.org/church-discipline.jpg"
            alt="Church Discipline"
            width={150}
          />
          <PurchaseInfo>
            Purchase{' '}
            <a href="https://www.amazon.com/Church-Discipline-Protects-Building-Churches/dp/1433532336">
              here{' '}
            </a>
            or borrow from our church library. More info about our church
            library can be found on Discord.
          </PurchaseInfo>
        </BookInfo>
      </Stack>
    </>
  )
}

export default ChurchMembership
